//@flow
import * as React from "react";

type Props = {
    ...React.ElementProps<'iframe'>,
    title: string,
    onLoad?: Function
};

class Iframe extends React.PureComponent<Props> {

    iframe: ?HTMLIFrameElement;

    componentDidMount() {
        const {onLoad} = this.props;
        if (onLoad != null && this.iframe != null)
            this.iframe.addEventListener('load', onLoad);
    }

    componentWillUnmount() {
        const {onLoad} = this.props;
        if (onLoad != null && this.iframe != null)
            this.iframe.removeEventListener('load', onLoad);
    }

    render() {
        const {title, ...props} = this.props;
        delete props.onLoad;

        return (
            <iframe
                {...props}
                title={title}
                ref={e => this.iframe = e}
            />
        );
    }
}

export default Iframe;
//@flow
import * as React from "react";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import Airtable from "../Airtable/Airtable";

type Props = {
    languages: Array<{
        language: string,
        airtableViewToken: string
    }>
}

type State = {
    activeTab: number
}

class LanguagesTabs extends React.PureComponent<Props, State> {

    state = {
        activeTab: 0
    };

    toggle = (tab: number) => this.state.activeTab !== tab && this.setState({
        activeTab: tab
    });

    render() {
        const tabs = this.props.languages.map((item, index) => ({...item, index}));
        const {activeTab} = this.state;
        return (
            <div>
                <Nav tabs>
                    {tabs.map(({index, language}) => (
                        <NavItem key={index}>
                            <NavLink
                                className={classnames({active: activeTab === index})}
                                onClick={() => this.toggle(index)}
                            >
                                {language}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
                <TabContent activeTab={activeTab}>
                    {tabs.map(({index, airtableViewToken}) => (
                        <TabPane tabId={index} key={index}>
                            <Airtable token={airtableViewToken} card/>
                        </TabPane>
                    ))}
                    <TabPane tabId={999}>
                        <Airtable token="shrP2hfgDRyT2gKBY" />
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}

export default LanguagesTabs;
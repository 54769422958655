//@flow
import * as React from "react";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    NavLink,
    UncontrolledDropdown
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import faPlusCircle from '@fortawesome/fontawesome-free-solid/faPlusCircle';
import type {User} from "../user/types";
import languagesConfig from "../languages/config";
import LanguagesTabs from "./LanguagesTabs/LanguagesTabs";

type Props = {
    logout: Function,
    user: User
}

type State = {
    addText: boolean
}

class AuthenticatedApp extends React.PureComponent<Props, State> {

    tabs: *;

    constructor(props: Props) {
        super(props);
        this.tabs = React.createRef();
    }

    displayAddForm = () => {
        this.tabs.current.toggle(999);
    };

    render() {
        const {logout, user} = this.props;

        return (
            <div>
                <Navbar dark className="rounded my-3" expand="md">
                    <NavbarBrand href="#">Blue Pencil</NavbarBrand>
                    <Nav navbar className="ml-auto">
                        <NavItem>
                            <NavLink href="#" onClick={this.displayAddForm}><FontAwesomeIcon icon={faPlusCircle}/> Add a text</NavLink>
                        </NavItem>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                {user.displayName}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem href="#" onClick={logout}>
                                    logout
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Navbar>
                <LanguagesTabs addText={false} languages={languagesConfig} ref={this.tabs} />
            </div>
        )
    }
}

export default AuthenticatedApp;
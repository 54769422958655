//@flow
import * as React from "react";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from "../../firebase";

const uiConfig = {
    signInFlow: 'popup',
    callbacks: {
        signInSuccess: () => false
    },
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ]
};


const Authentication = () => (
    <div id="authentication">
        <h1 id="title" className="mt-5">Welcome to Blue Pencil</h1>
        <hr className="mx-auto my-5 purple-border"/>
        <p className="subheading w-50 mx-auto">Don't let a few mistakes prevent you from success.</p>
        <div className="w-50 mt-5 rounded mx-auto p-4 purple-border">
            <p className="mb-2" id="label-login">To get started, sign up or create an account:</p>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
        </div>
    </div>
);

export default Authentication;
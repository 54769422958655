import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";

const config = {
    apiKey: "AIzaSyBeQiUKXnvaU1LQhLuYwEJYj1dOTaHvMjs",
    authDomain: "blue-pencil-app.firebaseapp.com",
    databaseURL: "https://blue-pencil-app.firebaseio.com",
    projectId: "blue-pencil-app",
    storageBucket: "blue-pencil-app.appspot.com",
    messagingSenderId: "438457708041",
    appId: "1:438457708041:web:a009de93b454e70b8892d8"
};

firebase.initializeApp(config);

export default firebase;
//@flow
import * as  React from "react";
import firebase from "../firebase";
import Authentication from "./Authentication/Authentication";
import AuthenticatedApp from "./AuthenticatedApp";
import type {User} from "../user/types";

type Props = {||};

type State = {
    user: User;
};

class App extends React.PureComponent<Props, State> {

    state = {
        user: {
            isAuthenticated: false,
            displayName: ''
        }
    };

    unregisterAuthObserver = null;

    loggedIn = (user: firebase.User) => {
        if (document.body != null) document.body.classList.remove('not-authenticated');
        this.setState({
            user: {
                isAuthenticated: true,
                displayName: user.displayName
            }
        });
    };

    logout = () => {
        firebase.auth().signOut();
        this.setState({
            user: {
                isAuthenticated: false
            }
        });
        if (document.body != null) document.body.classList.add('not-authenticated');
    };

    componentDidMount() {
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            user => !!user && this.loggedIn(user)
        );
    }

    componentWillUnmount() {
        if (this.unregisterAuthObserver != null) this.unregisterAuthObserver();
    }

    render() {
        const {user} = this.state;
        return (!user.isAuthenticated) ? (
            <Authentication/>
        ) : (
            <AuthenticatedApp logout={this.logout} user={user}/>
        );
    }
}

export default App;
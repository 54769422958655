//@flow
import React from "react";
import IframeLoader from "../Common/IframeLoader";
import LoadingState from "../Common/LoadingState";

type Props = {
    token: string,
    card?: boolean,
    controls?: boolean
};

const Airtable = ({token, card, controls}: Props) => {

    const layout = (card) ? '&layout=card' : '';
    const viewControls = (controls) ? '&viewControls=on' : '';

    return (
        <IframeLoader
            className="airtable-embed"
            title='airtable embed'
            src={`https://airtable.com/embed/${token}?backgroundColor=orange${layout}${viewControls}`}
            frameBorder="0"
            onmousewheel=""
            width="100%"
            height="750px"
            style={{background: 'transparent'}}
            loading={<LoadingState style={{height: "750px"}}/>}
        />
    );
};

Airtable.defaultProps = {
    card: false
};

export default Airtable;
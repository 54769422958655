//@flow
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import faLoad from '@fortawesome/fontawesome-free-solid/faSyncAlt';
import type {Styles} from "../../common/types";

type Props = {
    style?: Styles
};

const LoadingState = ({style}: Props) => (
    <div id="loading" className="text-center py-3 d-flex flex-column align-items-center justify-content-center" style={style}>
        <h2 className="mb-5">Searching for new texts to proofread...</h2>
        <FontAwesomeIcon icon={faLoad} spin size="3x"/>
    </div>
);

export default LoadingState;
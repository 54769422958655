//@flow
import * as React from "react";
import Iframe from "./Iframe";

type PropsToRemove = {
    onLoad?: Function
};

type Props = {
    ...$Diff<React.ElementProps<typeof Iframe>, PropsToRemove>,
    loading: React.Node
}

type State = {
    isLoading: boolean
}

class IframeLoader extends React.PureComponent<Props, State> {

    state = {
        isLoading: true
    };

    loaded = () => this.setState({isLoading: false});

    render() {

        const {loading, ...props} = this.props;
        const {isLoading} = this.state;
        const iframeStyle = isLoading ? {display: "none"} : {};

        return (
            <div className="p-0 m-0">
                {this.state.isLoading && loading}
                <Iframe {...props} onLoad={this.loaded} style={iframeStyle}/>
            </div>
        );
    }
}

export default IframeLoader;